import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "노인복지와 어르신 돌봄",
  "slug": "data16",
  "date": "2022-09-29T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb16.png",
  "featuredImg": "../images/featured/featured16.png",
  "summary": "아이들 유치원 가듯, 노인도 센터 간다면…집에서 여생을 보낼 수 있을까?",
  "tags": ["노인", "노인복지", "어르신돌봄", "요양병원"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`"아이들 유치원 가듯, 노인도 센터 간다면"…집에서 여생을 보낼 수 있을까?`}</p>
    <p>{`853만7000명. 통계청의 ‘2021년 고령자 통계’를 보면, 대한민국 인구의 16.5%가 65살 이상 노인이다. 정부는 2008년 장기요양보험제도를 도입해 노인 돌봄을 공공의 영역으로 끌어들이고, 2018년엔 살던 곳에서 노후를 보낼 수 있게 하겠다며 ‘지역사회 통합돌봄’(커뮤니티 케어) 시범사업을 시작했다. 하지만 상당수 노인들은 여전히 ‘살던 곳’이 아닌 요양시설·병원에서 노년을 보내고 있다. 2020년 말 서울대 산학협력단의 ‘의료공급체계 개선 이행전략 개별 연구 보고서)를 보면, 시설에 있는 노인 10명 가운데 6명은 지역사회에서 최소한의 돌봄으로 생활이 가능하지만, 불필요하게 요양시설·병원에 입원한 사람들이다. 연구를 진행한 김윤 서울대 교수(의료관리학)는 이를 ‘현대판 고려장’이라고 표현했다.`}</p>
    <p>{`돌봄과 관련하여.
자식의 죄의식, 노년의 공포, 정부의 미필적 고의를 살펴보기 위해, 한겨레·돌봄과미래·공공의창이 돌봄을 주제로 60세 이상 어르신 700명과, 최근 부모를 떠나보낸 50대 여성 5명의 이야기를 들어보았다.`}</p>
    <h2>{`건강이나 경제상태에 따른 일상 영향력`}</h2>
    <p>{`만 60세 이상 어르신들의 58.0%가 본인의 건강상태에 따른 일상생활에서의 불편함이 있다고 답했으며, 69.8%가 경제적 여건에 따른 일상생활에서의 불편함이 있다고 응답 하였다.`}</p>
    <h2>{`보호·요양시설 이미지`}</h2>
    <p>{`보호센터, 요양원, 요양병원 등을 떠올렸을 때 연상되는 느낌은 70.6%가 부정적인 이 미지(서글픔 44.1%, 불편 16.2%, 공포감 6.5%, 부작용 3.8% 순)를 떠올렸고, 17.7%만이 긍정적 이미지(안전함 10.3%, 희망 4.6%, 쾌적함 2.8% 순)를 떠올렸다.`}</p>
    <h2>{`정부·공공기관의 지원 필요성`}</h2>
    <p>{`정부·공공기관의 지원 필요성에 대해 물어본 결과, 경제적인 어려움에 대한 도움이 필 요하다는 응답이 72.0%, 일상생활에 대해 도움이 필요하다는 응답이 50.2%로 조사되 었다.`}</p>
    <p>{`우리나라의 어르신 돌봄 정책에 대해 68.3%가 긍정적으로 평가했고 현재 시행 중인 연명치료 결정법에 대해 ‘몰랐거나(20.3%), 알고 있지만 생각해 보지 않았다’(21.8%)는 응답자는 42.1%, ‘알고 있고 언젠가 알아 볼 생각’이라는 응답자는 40.1%, ‘잘 알고 있으며 이미 연명치료 거부 및 중단에 대한 동의서도 작성했다’는 응답자는 11.2%로 조사되었다.`}</p>
    <h2>{`57.2% “가족과 함께 집에서 노년을 보내고 싶다.”`}</h2>
    <p>{`응답자 중 24.1%는 주변에 조력자(일상적 생활의 어려움에 대해 의논하거나 도움을 요청할 사람)가 없다고 응답하였으나, 향후 노년을 집에서 보내고 싶다는 응답이 70.1%(가족 57.2%, 혼자 12.9%)로, 요양시설에서 지내고 싶다는 응답(15.2%)보다 높았고, 가족 또는 지인과 어울려서 지내고 싶다는 응답이 76.2%(집 57.2%, 요양시 설 19.0%)로, 혼자 보내고 싶다는 응답(21.3%)보다 높게 조사되었다.`}</p>
    <ChartBase type={'pie'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`가족들 돌본 경험이 있는 자녀들은 모두 요양병원을 “죽으러 가는 최악의 장소”로 꼽았다. 친정 아버지를 요양병원에 보낸 경험이 있는 박미라(가명·55살)씨는 “사람 대우를 받을 수 없다. 요양병원에 들어가기 전에 죽고 싶다”고 말했다. 이들은 요양병원이 요양 뒤에 ‘병원’이 붙지만, 요양도 제대로 된 의료서비스도 받을 수 없는 곳이라고 입을 모았다.`}</p>
    <p>{`어떻게 이런 돌봄의 악순환을 끊을 수 있을까? 이번 여론조사에 참여해주신 가족 돌봄 경험자인 희원씨는 “시어머니를 주야간보호센터에 보낼 때 아무런 돌봄을 제공할 수 없는 집보다 기관이라도 가는게 낫지 않겠냐 생각했다. 근데 이것저것 다 해보니 그래도 가정에 머물며 내 친한 이웃과 함께 있는게 어떤 전문적인 프로그램보다 나은 거 같다”고 말했다. 이 때문에 희원씨는 “돌봄 기관이 큰 기업으로 있기 보다 동네 소규모로 가족처럼 꾸려지는게 맞다고 본다”고 생각한다. 이는 2017년 노인실태조사에서 57.6%의 노인들이 “거동이 불편해서 살던 곳에서 여생을 마치고 싶다”고 답한 것과 맥락을 함께 한다. 정부도 이런 상황을 반영해 노인이 요양시설·병원에 가지않고 살던 집, 동네에서 돌봄을 받을 수 있도록 2018년 ‘지역사회 통합 돌봄(커뮤니티케어) 기본계획’을 발표했다. 2019년 시작한 시범사업은 올해 말 종료된다.`}</p>
    <p>{`가족 돌봄 경험자들은 각자 처한 상황은 달랐지만, 공통으로 ‘주간 돌봄 서비스 의무화’에 공감했다. 현재 주야간보호센터를 이용하려면 노인장기요양보험에서 정한 3~5등급을 받아야 하는데, 등급을 받기 전 비교적 건강한 상태일 때부터 센터에 갈 수 있게 기회를 열어달라는 취지다. 김희원씨는 “유아들이 유치원에 가듯이 노인들도 센터에 간다면 저항감도 덜 할 것 같다. 등급에 상관없이 하루라도 빨리 가야 치매도 늦추고 건강한 노후를 유지할 수 있다고 생각한다”고 말했다.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      